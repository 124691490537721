import "../styles/Founder.css";
import Background from "../assets/team/founder/founderbg.png";
import Team from "../assets/team/Team.png";
import Trikota from "../assets/team/Trikona.png";
import Avp from "../assets/team/AVP.png";
import FounderHeader from "../assets/team/founder/founderbg.png";
import Background1 from "../assets/aboutUs/aboutmask.png";
import Founderbgfull from '../assets/team/founder/founder_banner_full.png'

const Founder = () => {
  return (
    <>
      {/* Landing page */}
      {/* <section className="founderBannerImg">
        <div className="allContent">
          <div className="imgContent">
            <div className="imgContent1">
              <img src={Background1} className="img-fluid" alt="Background" />
            </div>
            <div className="imgContent2">
              <img
                src={FounderHeader}
                className="img-fluid"
                alt="Background2"
              />
            </div>
            <div className="textContent">
              <h1>
                Our <span>Story</span>
              </h1>
              <p>
                A Decade of Shaping India’s Infrastructure <br />
                with Steel and Innovation
              </p>
            </div>
          </div>
        </div>
      </section> */}
      
      <section className="aboutbgtestFounders">
        <div className="allaboutbg">
          <div className="imgforbg">
            <img src={Founderbgfull} className="img-fluid" alt="About Us" />
          </div>
          <div className="allaboutcontent">
            <h1>Founders</h1>
          </div>
        </div>
      </section>

      {/* founders card */}
      <section className="foundersCard mt-5">
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="card col-md-12 p-3">
                <div className="row ">
                  <div className="col-md-4">
                    <img className="w-100" src={Team} />
                  </div>
                  <div className="col-md-8">
                    <div className="card-block">
                      <h6 className="card-title">
                        Co-Founder & Head of Business Development
                      </h6>
                      <h3>Avijit Guha</h3>
                      <div className="social-logos">
                        <i className="fa-brands fa-facebook"></i>
                        <i className="fa-brands fa-x-twitter"></i>
                        <i className="fa-solid fa-phone"></i>
                      </div>
                      <p className="card-text text-justify">
                        Avijit brings 12 years of experience in Business &
                        Organizational Development, having previously served as
                        AVP responsible for Profit Centre Operations at a major
                        American Fund Manager. He drives business growth,
                        long-term strategy, technology upgrades, and factory
                        development. Avijit oversees daily sales and production
                        activities. He holds a degree in Electronics, a
                        post-graduate diploma in Software Engineering and
                        Computer Applications, and an MBA from the National
                        Productivity Council (Govt. of India, Delhi).
                      </p>
                      <img
                        src={Trikota}
                        className="img-fluid mt-3"
                        alt="Trikota"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="card col-md-12 p-3">
                <div className="row ">
                  <div className="col-md-8">
                    <div className="card-block">
                      <h6 className="card-title text-right">
                        Co-Founder & Head of Operations and Marketing
                      </h6>
                      <h3>Jaya Guha</h3>
                      <div className="social-logos">
                        <i className="fa-brands fa-facebook"></i>
                        <i className="fa-brands fa-x-twitter"></i>
                        <i className="fa-solid fa-phone"></i>
                      </div>
                      <p className="card-text text-justify">
                        Jaya brings 14 years of experience in Sales and
                        Marketing, previously serving as AVP for an
                        Indo-Japanese Insurance Company. She manages business
                        operations, sales, marketing strategy, banking &
                        finance, and organizational compliance. Jaya holds a
                        Commerce degree and an MBA with a specialization in
                        Finance and Marketing.
                      </p>
                      <img src={Avp} alt="AVP" className="img-fluid mt-3" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img className="w-100" src={Team} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Founder;
