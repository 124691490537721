import React from 'react';
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from 'react-icons/fa';
import Background from '../assets/contactusbg.png'
import '../styles/ContactUs.css'
import Background1 from "../assets/aboutUs/aboutmask.png";
import Contactbgfull from '../assets/contactus_banner_full.png'


const ContactUs = () => {
  return (
    <div>
      <section className="aboutbgtestcontactUS">
        <div className="allaboutbg">
          <div className="imgforbg">
            <img src={Contactbgfull} className="img-fluid" alt="About Us" />
          </div>
          <div className="allaboutcontentcontact">
            <h1>
              <span>Contact</span> Us
            </h1>
          </div>
        </div>
      </section>

      <main className="container my-4 contactheading2">
        <section className="mb-8">
          <div className="row">
            <div className="col-md-7 p-4 bg-white rounded-lg">
              <h2 className="mb-2 contactinfo">
                CONTACT INFO
              </h2>
              <h1 className="display-4 mb-1 getintext"><span>Get In</span> Touch</h1>
              <div className="w-25 h-1 bg-primary mb-4"></div>
              <p className="mb-4">
                Building dreams, one structure at a time. Let’s create something
                extraordinary together.
              </p>
              <ul className="mt-4 list-unstyled">
                <li className="d-flex align-items-start mb-4">
                  {/* <FaMapMarkerAlt className="text-primary h4 mr-2" /> */}
                  <i className="fa-solid fa-location-dot p-3"></i>
                  <div>
                    <span className="d-block contactSpan">
                      Our Location:
                    </span>
                    <span>
                      Nagpur: W-12/12 MIDC Hingna Road Area, Nagpur, Maharashtra
                      -27.
                    </span>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  {/* <FaEnvelope className="text-primary h4 mr-2" /> */}
                  <i className="fa-solid fa-envelope p-3"></i>
                  <div>
                    <span className="d-block contactSpan">
                      Email Address:
                    </span>
                    <span>info@metalbarns.in</span>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  {/* <FaPhone className="text-primary h4 mr-2" /> */}
                  <i className="fa-solid fa-phone p-3"></i>
                  <div>
                    <span className="font-weight-bold d-block contactSpan">
                      Phone Number:
                    </span>
                    <span>+91 9822674417</span>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-5 ml-md-4 p-4 bg-light rounded-lg formSec">
              <h2 className="mb-4 sendUstext">
                Send Us A <span className="textMessage">Message</span>
              </h2>
              <form className="mt-4">
                <div className="mb-4">
                  <label className="form-label" htmlFor="name">
                    Your Name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    id="email"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label" htmlFor="subject">
                    Subject
                  </label>
                  <input className="form-control" type="text" id="subject" />
                </div>
                <div className="mb-4">
                  <label className="form-label" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="4"
                  ></textarea>
                </div>
                <button className="btn text-white px-4 py-2 rounded">
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="mt-8">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.18897804325!2d79.00368447525976!3d21.144876380533606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4eb5066abef8d%3A0xb2b1da68f8a1efcb!2sMetal%20Barns%20India!5e0!3m2!1sen!2sin!4v1719487641718!5m2!1sen!2sin"
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
              className="border-0 mt-5 mb-5"
              title="Company Location"
            ></iframe>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ContactUs;
