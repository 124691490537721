import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "../styles/CustomerCentric.css";
import Background from "../assets/aboutUs/aboutmask.png";
import CustomerCentricBg from "../assets/innovation/customercentric.png";
import Warehouse from "../assets/innovation/caraousel/warehousecolumns2.jpg";
import Rccp from "../assets/innovation/caraousel/rccpebhybrid1.jpg";
import Cold from "../assets/innovation/caraousel/coldstorage1.jpg";
import Hanger from "../assets/innovation/caraousel/hangerdoors1.jpg";
import Customerbgfull from '../assets/innovation//customer_centric_banner_full.png'


const customerCentricData = [
  {
    title: "Warehouse Without Column Foundations",
    description:
      "Showcasing our innovative approach in structural engineering, our 3,500 sq.ft. warehouse for a multinational in Nagpur stands without traditional column foundations, utilizing advanced PCC techniques.",
    imgSrc: Warehouse,
    // iconSrc: "/images/warehouseicon.png",
  },
  {
    title: "RCC PEB Hybrid Structures",
    description:
      "Our RCC PEB hybrid structures combine the best of reinforced cement concrete and pre-engineered building techniques to deliver unmatched durability and efficiency.",
    imgSrc: Rccp,
    // iconSrc: "/images/rccpebhybridicon.png",
  },
  {
    title: "Cold Storage Solutions",
    description:
      "Our cold storage solutions ensure optimal temperature control and preservation of perishable goods, utilizing state-of-the-art technology.",
    imgSrc: Cold,
    // iconSrc: "/images/coldstorageicon.png",
  },
  {
    title: "Hanger Doors for Aviation",
    description:
      "Our hanger doors for aviation are designed to provide maximum space utilization and ease of operation, ensuring smooth aircraft movement.",
    imgSrc: Hanger,
    // iconSrc: "/images/hangericon.png",
  },
];

const CustomerCentric = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handlePrevClick = () => {
      setCurrentSlide((prevSlide) => (prevSlide === 0 ? customerCentricData.length - 1 : prevSlide - 1));
    };
  
    const handleNextClick = () => {
      setCurrentSlide((prevSlide) => (prevSlide === customerCentricData.length - 1 ? 0 : prevSlide + 1));
    };

  return (
    <>
      <section className="aboutbgtest">
        <div className="allaboutbg">
          <div className="imgforbg">
            <img src={Customerbgfull} className="img-fluid" alt="About Us" />
          </div>
          <div className="allaboutcontentCentric">
            <h1>
              Customer-Centric
              <br />
              <span> Innovation</span>
            </h1>
          </div>
        </div>
      </section>

      {/* caraousel */}
      <section
        className="py-1 px-3 position-relative w-100 carousel-section"
        style={{
          backgroundImage: "url(/images/customercentricbg.png)",
          marginBottom: "50px",
        }}
      >
        <div
          className="container position-relative customerHeightContainer"
          style={{ minHeight: "100vh" }}
        >
          <div
            className="d-flex justify-content-between align-items-center position-relative"
            style={{ height: "100%" }}
          >
            <button
              className="btn btn-dark position-absolute left-arrow"
              onClick={handlePrevClick}
            >
              <FaArrowLeft size={24} />
            </button>
            <div
              className="w-100 d-flex justify-content-center align-items-center position-relative overflow-hidden imgCentricDiv"
              style={{ height: "135vh" }}
            >
              {customerCentricData.map((slide, index) => (
                <div
                  key={index}
                  className={`carousel-slide position-absolute transition-transform duration-500 ease-in-out ${
                    index === currentSlide
                      ? "translate-x-0 z-40 active-slide"
                      : index ===
                        (currentSlide - 1 + customerCentricData.length) %
                          customerCentricData.length
                      ? "translate-x-n50 opacity-10 z-30"
                      : index ===
                        (currentSlide + 1) % customerCentricData.length
                      ? "translate-x-50 opacity-10 z-30"
                      : "translate-x-full opacity-0 z-20"
                  }`}
                  style={{ width: "50%" }}
                >
                  <div className="position-relative w-100 h-100 d-flex align-items-center justify-content-center carouselimg">
                    <img
                      src={slide.imgSrc}
                      alt={slide.title}
                      className="carousel-image"
                    />
                  </div>
                  <div className="bg-white p-2 rounded shadow w-100 text-center textAboveCard">
                    <h2 className="h3 mb-1">{slide.title}</h2>
                    <p>{slide.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <button
              className="btn btn-dark position-absolute right-arrow"
              onClick={handleNextClick}
            >
              <FaArrowRight size={24} />
            </button>
          </div>
        </div>
      </section>
    </>
  );
};
export default CustomerCentric;
