import React, { useState, useEffect } from "react";
import "../styles/CommitmentQuality.css";
import CommitmentBg from '../assets/innovation/CommitmentBanner.png'
import Background from "../assets/aboutUs/aboutmask.png";
import BestClass from "../assets/innovation/bestinclasstech.png";
import QualityConsume from "../assets/innovation/qualityconsumables.png";
import ProcessFlow from "../assets/innovation/processflow.png";
import Documentation from "../assets/innovation/documentation.png";
import { FaArrowRight } from "react-icons/fa";
import CustomerFirst from '../assets/innovation/customerFirst.png'
import CommitmentQualityimg from '../assets/innovation/Qualitycommitment.png'
import DrivenInnovation from '../assets/innovation/DrivenbyInnovations.png'
import TimeManagement from '../assets/innovation/TimemanagedProjects.png'
import Tatasteel from '../assets/innovation/tatasteel.png'
import Jsw from '../assets/innovation/jsw.png'
import Saillogo from '../assets/innovation/saillogo.png'
import Ador from '../assets/innovation/ador.png'
import Esabllogo from '../assets/innovation/esablogo.png'
import Bosch from "../assets/clients/client8.png";
import Aerlikonlogo from '../assets/innovation/aerlikonlogo.png'
import Bayerlogo from '../assets/innovation/bayerlogo.png'
import Qualitybgfull from '../assets/innovation/commitment_to_quality_banner_full.png'
import One from '../assets/innovation/caraousel/QualityPractice1.png'
import Two from '../assets/innovation/caraousel/QualityPractice2.png'
import Three from '../assets/innovation/caraousel/QualityPractice3.png'
import Four from '../assets/innovation/caraousel/QualityPractice4.png'
import Five from '../assets/innovation/caraousel/QualityPractice5.png'



const logos = {
  steel_primary_or_secondary: [
    { src: Tatasteel, alt: 'Tata Steel' },
    { src: Jsw, alt: 'JSW Steel' },
    { src: Saillogo, alt: 'SAIL' },
  ],
  equipment: [
    { src: Ador, alt: 'Ador' },
    { src: Esabllogo, alt: 'ESAB' },
    { src: Bosch, alt: 'Bosch' },
  ],
  consumables: [
    { src: Ador, alt: 'Ador' },
    { src: Aerlikonlogo, alt: 'Aerlikon' },
    { src: Bayerlogo, alt: 'Bayer' },
  ],
};

const carouselData = [
  {
    title: "Best in Class Technology",
    description:
      "At MBI, continuous investment in cutting-edge technology underpins our production capabilities. From precise welding to advanced cutting techniques, we ensure every product meets stringent quality standards.",
    imgSrc: BestClass,
  },
  {
    title: "Quality Consumables & Accessories",
    description:
      "Using globally sourced, top-tier materials enables MBI to produce exceptionally robust and durable structures. Our commitment extends to every screw and polish, ensuring unmatched quality.",
    imgSrc: QualityConsume,
  },
  {
    title: "Process Flow",
    description:
      "Our processes are designed for efficiency and consistency, minimizing human error and maximizing output quality.",
    imgSrc: ProcessFlow,
  },
  {
    title: "Comprehensive Documentation",
    description:
      "Rigorous checks and balances are integral to our operations. Each project undergoes thorough inspections and documentation, guaranteeing that only the best products leave our factory.",
    imgSrc: Documentation,
  },
];

const cardsDataInno = [
  {
    title: 'Technology Upgrade & Process Orientation',
    description: 'Production is supported by the latest technology, and process augmentation with correct planning and execution documents. Our commitment ensures that every step is backed by modern tools and methodologies.',
    iconSrc: CustomerFirst,
    cardIcon: CustomerFirst,
    selectedIconSrc: One,
  },
  {
    title: 'Batch Packaging and Quantity Labeling as a MUST DO',
    description: 'We package loose material to counted excesses, reducing search and fit operations at the site. This practice also controls pilferage during transport and stowing, ensuring everything is accounted for and ready for use.',
    iconSrc: CommitmentQualityimg,
    cardIcon: CommitmentQualityimg,
    selectedIconSrc: Two,
  },
  {
    title: 'Standard Formats – Simplified for Internal and External Communication',
    description: 'We have designed our own simplified process documents and formats. These enable active tracking of production, rectification, packaging, dispatch, and billing. Easy-to-read documents help clarify both internal and external communication, ensuring seamless operations.',
    iconSrc: DrivenInnovation,
    cardIcon: DrivenInnovation,
    selectedIconSrc: Three,
  },
  {
    title: 'Safety at Every Step – Production, Dispatch & Erection',
    description: 'Safety is paramount in all our operations. We emphasize the safety of both man and equipment to maintain low downtime. Dispatch plans in AutoCAD ensure 100% utilization and proper transport stacking. Safety gears are worn even for unloading vehicles, and site safety trainings and checks are conducted to ensure eventless execution.',
    iconSrc: TimeManagement,
    cardIcon: TimeManagement,
    selectedIconSrc: Four,
  },
  {
    title: 'Quality Raw Materials & Consumables',
    description: 'We believe that uncompromised input ensures reasonable quality output. We adhere to high-quality inputs in the form of raw materials and consumables, meeting design specifications. Our maintained equipment and quality practices lead to superior output, every time.',
    iconSrc: TimeManagement,
    cardIcon: TimeManagement,
    selectedIconSrc: Five,
  },
];

const CommitmentQuality = () => {
  const [selectedCategory, setSelectedCategory] = useState('steel_primary_or_secondary');

  const [selectedCard, setSelectedCard] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formatCategory = (category) => {
    switch (category) {
      case 'steel_primary_or_secondary':
        return 'Steel Primary or Secondary';
      case 'equipment':
        return 'Equipment';
      case 'consumables':
        return 'Consumables';
      default:
        return category;
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
  };

  const { title, description, imgSrc } = carouselData[currentIndex];

  return (
    <>
      <section className="aboutbgtestCommitmentQuality">
        <div className="allaboutbg">
          <div className="imgforbg">
            <img src={Qualitybgfull} className="img-fluid" alt="About Us" />
          </div>
          <div className="allaboutcontentCommit">
            <h1>
              Commitment to
              <br />
              <span> Quality</span>
            </h1>
          </div>
        </div>
      </section>

      {/* caraousel */}
      <section className="comitQuality">
        <div className="carousel-container container bg-white p-4 shadow-lg rounded">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="qualityTitle mb-4">{title}</h2>
              <p className="text-dark">{description}</p>
            </div>
            <div className="col-md-6">
              <img src={imgSrc} alt={title} className="img-fluid rounded" />
            </div>
          </div>
          <button
            onClick={handleNext}
            className="btn btn-light position-absolute translate-middle-y bottom-0 end-0"
            // className="btn btn-light border position-absolute top-50 end-0 translate-middle-y shadow-lg"
          >
            <FaArrowRight size={20} />
          </button>
        </div>
      </section>

      {/* Steel primary logos */}
      <section className="primary-logos">
        <div className="container">
          <div className="w-100 h-64 bg-white d-flex flex-column align-items-center">
            <div className="d-flex justify-content-center mb-4">
              {["steel_primary_or_secondary", "equipment", "consumables"].map(
                (category, index) => (
                  <button
                    key={index}
                    className={`btn btn-outline-secondary mx-2 ${
                      selectedCategory === category ? "active" : ""
                    }`}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {formatCategory(category)}
                  </button>
                )
              )}
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              {logos[selectedCategory]?.map((logo, index) => (
                <div
                  key={index}
                  className="col-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center shadow-sm rounded m-2 logo-container"
                >
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    className="img-fluid w-80 h-100 object-contain"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Quality Practices */}
      <section className="qualityPractices">
        <section className="qualityPracticesInnovation">
          <h1 className="d-flex text-white justify-content-center align-items-center pt-5 practicesHeading">
            Quality Practices
          </h1>
          <div className="d-flex justify-content-center align-items-center position-relative bg-transparent z-index-30 w-75 mx-auto">
            <div className="col-md-5 p-4 bg-transparent position-relative">
              {cardsDataInno.map((card, index) => (
                <div
                  key={index}
                  className={`d-flex align-items-center p-4 mb-4 cursor-pointer transition-all duration-300 ${
                    selectedCard === index
                      ? "bg-custom selected-card"
                      : "bg-white text-dark"
                  }`}
                  onClick={() => setSelectedCard(index)}
                >
                  <img
                    src={
                      selectedCard === index
                        ? card.selectedIconSrc
                        : card.iconSrc
                    }
                    alt={card.title}
                    className="me-4"
                    style={{ width: "32px", height: "32px" }}
                  />
                  <span>{card.title}</span>
                  {selectedCard === index && <div className="arrow-right" />}
                  {isMobile && selectedCard === index && (
                    <div className="selected-card-content position-relative p-4">
                      <div className="position-relative p-4 bg-white text-dark">
                        <img
                          src={cardsDataInno[selectedCard].cardIcon}
                          alt="Card Icon"
                          className="mb-4"
                          style={{ width: "64px", height: "64px" }}
                        />
                        <h2 className="h4 font-weight-bold mb-4 card-title-custom">
                          {cardsDataInno[selectedCard].title}
                        </h2>
                        <p className="text-dark">
                          {cardsDataInno[selectedCard].description}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {!isMobile && (
              <div className="col-md-7 p-4 customdiv">
                {selectedCard !== null && (
                  <div className="position-relative p-4">
                    <div className="position-relative z-index-20 p-4">
                      <img
                        src={cardsDataInno[selectedCard].cardIcon}
                        alt="Card Icon"
                        className="mb-4"
                        style={{ width: "64px", height: "64px" }}
                      />
                      <h2 className="h4 font-weight-bold mb-4 card-title-custom">
                        {cardsDataInno[selectedCard].title}
                      </h2>
                      <p className="text-dark">
                        {cardsDataInno[selectedCard].description}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      </section>
    </>
  );
};

export default CommitmentQuality;
