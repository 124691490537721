import '../styles/InnovationMiddle.css'
import React from 'react';
import CompanyOwned from '../assets/innovation/caraousel/latestTech/companyownedfacility.png'
import CncCutting from '../assets/innovation/caraousel/latestTech/cnccutting.png'
import Submerged from '../assets/innovation/caraousel/latestTech/submergedarcwelding.png'
import MiddleBg from '../assets/innovation/caraousel/innovationmiddle.png'

const InnovationMiddle = () => {
  return (
    <section className='innovationMiddleSec'>
      <div className="position-relative w-100 h-auto py-5 bg-white">
      {/* Background Image */}
      <img
        src={MiddleBg}
        alt="Innovation Middle Background"
        className="position-absolute top-0 start-0 w-100 h-auto object-cover z-index-0"
      />

      {/* Customer-Centric Innovation Section */}
      {/* <div className="position-relative mb-5 z-index-10 text-center">
        <h2 className="h2 mb-4">Customer-Centric Innovation</h2>
        <div className="d-flex justify-content-center">
          <div
            className="d-flex flex-wrap justify-content-center p-4 bg-cover bg-center"
            style={{ backgroundImage: `url('/images/innovationmiddlesheet.png')` }}
          >
            {[
              { title: 'Warehouse Without Column Foundations', icon: '/images/icon1.png' },
              { title: 'PEB Hanger Doors', icon: '/images/icon2.png' },
              { title: 'Steel Bungalow & Island Structure', icon: '/images/icon3.png' },
              { title: 'Container Residences', icon: '/images/icon4.png' },
              { title: 'Large Structures in Less Steel', icon: '/images/icon5.png' },
            ].map((item, index) => (
              <div key={index} className="d-flex flex-column align-items-center w-25 h-25 m-2">
                <img src={item.icon} alt={item.title} className="w-25 h-25 mb-2" />
                <span className="text-center">{item.title}</span>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {/* Commitment To Quality Section */}
      {/* <div className="position-relative mb-5 z-index-10 text-center">
        <div className="position-absolute top-50 start-15 translate-middle w-2 bg-gray border border-1 border-gray rotate-45"></div>
        <h2 className="h2 mb-4">
          <span>Commitment To <span className="text-primary">Quality</span></span>
          <div className="mt-2 mx-auto w-25 bg-gray border border-1 border-gray"></div>
        </h2>
        <div className="d-flex justify-content-center align-items-center bg-white shadow p-4">
          <img src="/images/quality.png" alt="Quality" className="w-50 h-auto object-cover" />
          <div className="p-4">
            <h3 className="h3">Process Flow & Ease Of Working</h3>
            <p className="text-muted">Description about process flow and ease of working.</p>
            <button className="btn btn-primary mt-4">Learn More →</button>
          </div>
        </div>
      </div> */}

      {/* Latest Technology Section */}
      <div className="position-relative z-index-10 text-center innomiddle">
        <div className="position-absolute top-50 start-15 translate-middle w-2 bg-gray border border-1 border-gray rotate-45"></div>
        <h2 className="h2 mb-4">
          <span>Latest <span className="textTech">Technology</span></span>
          <div className="mt-2 mx-auto w-25 bg-gray border border-1 border-gray"></div>
        </h2>
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-flex overflow-auto snap-x snap-mandatory">
            {[
              { title: 'Company Owned Facility', img: CompanyOwned },
              { title: 'CNC Cutting', img: CncCutting },
              { title: 'Submerged Arc Welding', img: Submerged },
            ].map((item, index) => (
              <div key={index} className="flex-shrink-0 w-25 h-25 d-flex flex-column align-items-center bg-white shadow p-4 m-2">
                <img src={item.img} alt={item.title} className="w-100 h-auto object-cover mb-2" />
                <span className="text-center">{item.title}</span>
              </div>
            ))}
          </div>
        </div>
        <button className="btn btn-primary mt-4">Learn More →</button>
      </div>
    </div>
    </section>
  );
};

export default InnovationMiddle;
