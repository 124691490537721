import "../styles/Tabs.css";
import React, { useState } from "react";
import Caraousel1 from '../assets/aboutUs/caraouselLogos/companyTimelineCaraousel1.png'
import Caraousel2 from '../assets/aboutUs/caraouselLogos/Caraousel20141.jpg'
import Caraousel3 from '../assets/aboutUs/caraouselLogos/Caraousel20151.jpg'
import Caraousel4 from '../assets/aboutUs/caraouselLogos/Caraousel20161.jpg'
import Caraousel5 from '../assets/aboutUs/caraouselLogos/Caraousel2017.png'
import Caraousel6 from '../assets/aboutUs/caraouselLogos/Caraousel20181.jpg'
import Caraousel7 from '../assets/aboutUs/caraouselLogos/Caraousel20211.jpg'
import Caraousel8 from '../assets/aboutUs/caraouselLogos/Caraousel2022.png'
import Caraousel9 from '../assets/aboutUs/caraouselLogos/Caraousel2023.png'


const Tabs = () => {
  const [activeStep, setActiveStep] = useState(1);

  const handleStepClick = (step) => {
    setActiveStep(step);
  };

  const getLineProgressWidth = () => {
    switch (activeStep) {
      case 1:
        return "3%";
      case 2:
        return "10%";
      case 3:
        return "15%";
      case 4:
        return "25%";
      case 5:
        return "45%";
      case 6:
        return "55%";
      case 7:
        return "65%";
      case 8:
        return "75%";
      case 9:
        return "85%";
      case 10:
        return "100%";
      default:
        return "3%";
    }
  };

  return (
    <div className="containerTab">
      <div className="container firstTimeline">
      <h4 className="text-center p-3">Over 10 years</h4>
      <h1 className="text-center">Company Timeline From <span>2014 to 2023</span></h1>
      <br />
      <div className="process-wrapper">
        <div id="progress-bar-container">
          <ul>
            <li
              className={`step step01 ${activeStep >= 1 ? "active" : ""}`}
              onClick={() => handleStepClick(1)}
            >
              <div className="step-inner">2014</div>
            </li>
            <li
              className={`step step02 ${activeStep >= 2 ? "active" : ""}`}
              onClick={() => handleStepClick(2)}
            >
              <div className="step-inner">2015</div>
            </li>
            <li
              className={`step step03 ${activeStep >= 3 ? "active" : ""}`}
              onClick={() => handleStepClick(3)}
            >
              <div className="step-inner">2016</div>
            </li>
            <li
              className={`step step04 ${activeStep >= 4 ? "active" : ""}`}
              onClick={() => handleStepClick(4)}
            >
              <div className="step-inner">2017</div>
            </li>
            <li
              className={`step step05 ${activeStep >= 5 ? "active" : ""}`}
              onClick={() => handleStepClick(5)}
            >
              <div className="step-inner">2018</div>
            </li>
            <li
              className={`step step05 ${activeStep >= 7 ? "active" : ""}`}
              onClick={() => handleStepClick(7)}
            >
              <div className="step-inner">2020</div>
            </li>
            <li
              className={`step step05 ${activeStep >= 8 ? "active" : ""}`}
              onClick={() => handleStepClick(8)}
            >
              <div className="step-inner">2021</div>
            </li>
            <li
              className={`step step05 ${activeStep >= 9 ? "active" : ""}`}
              onClick={() => handleStepClick(9)}
            >
              <div className="step-inner">2022</div>
            </li>
            <li
              className={`step step05 ${activeStep >= 10 ? "active" : ""}`}
              onClick={() => handleStepClick(10)}
            >
              <div className="step-inner">2023</div>
            </li>
          </ul>

          <div id="line">
            <div
              id="line-progress"
              style={{ width: getLineProgressWidth() }}
            ></div>
          </div>
        </div>

        <div id="progress-content-section">
          <div
            className={`section-content discovery ${
              activeStep === 1 ? "active" : ""
            }`}
          >
            <div className="row timelineCaraousel">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <img
                      src={Caraousel1}
                      className="img-fluid"
                      alt="Company Timeline"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-text">Warehouse without Column Foundation</h5>
                    <h6>Project: <span>10,000sq.ft, 25 Tons</span></h6>
                    <h6>Site: <span>Nagpur</span></h6>
                    <h6>Cumulative Project Area: <span>10,000 sqft</span></h6>
                    <p className="text-dark">
                    Pioneered a minimalist civil foundation for a PEB warehouse using Hilti Anchoring, 
                    ensuring durability and cost-efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`section-content strategy ${
              activeStep === 2 ? "active" : ""
            }`}
          >
                        <div className="row timelineCaraousel">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <img
                      src={Caraousel2}
                      className="img-fluid"
                      alt="Company Timeline"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-text">RCC & PEB Hybrid</h5>
                    <h6>Project: <span>10,000sqft, 40 Tons</span></h6>
                    <h6>Site: <span>High-End Automobile Showroom</span></h6>
                    <h6>Cumulative Project Area: <span>20,000 sqft</span></h6>
                    <p className="text-dark">
                    Designed and delivered a complex RCC to Steel structure with 
                    advanced joints and cantilevers for an automotive showroom.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`section-content creative ${
              activeStep === 3 ? "active" : ""
            }`}
          >
                       <div className="row timelineCaraousel">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <img
                      src={Caraousel3}
                      className="img-fluid"
                      alt="Company Timeline"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-text">PEB Hangar Doors</h5>
                    <h6>Project: <span>12 Nos. 15M * 5M, 80 Tons</span></h6>
                    <h6>Site: <span>Bangalore</span></h6>
                    <h6>Cumulative Project Area: <span>30,000 sqft</span></h6>
                    <p className="text-dark">
                    Introduced large-scale PEB hangar doors, enhancing the infrastructure of a major aviation facility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`section-content production ${
              activeStep === 4 ? "active" : ""
            }`}
          >
                        <div className="row timelineCaraousel">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <img
                      src={Caraousel4}
                      className="img-fluid"
                      alt="Company Timeline"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-text">Small-Scale Cold Storage</h5>
                    <h6>Project: <span>Small-scale cold storage</span></h6>
                    <h6>Cumulative Project Area: <span>40,000 sqft</span></h6>
                    <p className="text-dark">
                    Entered the cold storage market with a small-scale facility, laying the 
                    groundwork for future advanced storage solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`section-content analysis ${
              activeStep === 5 ? "active" : ""
            }`}
          >
                       <div className="row timelineCaraousel">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <img
                      src={Caraousel5}
                      className="img-fluid"
                      alt="Company Timeline"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-text">Design and Process Optimized</h5>
                    <h6>Focus: <span>Process optimization and several new projects</span></h6>
                    <h6>Cumulative Project Area: <span>60,000 sqft</span></h6>
                    <p className="text-dark">
                    Improved efficiency in design and execution, successfully completing 
                    multiple projects with optimized processes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`section-content production ${
              activeStep === 7 ? "active" : ""
            }`}
          >
                        <div className="row timelineCaraousel">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <img
                      src={Caraousel6}
                      className="img-fluid"
                      alt="Company Timeline"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-text">Advanced Data Center</h5>
                    <h6>Project: <span>50,000sq.ft</span></h6>
                    <h6>Site: <span>Jaipur</span></h6>
                    <h6>Cumulative Project Area: <span>110,000 sqft</span></h6>
                    <p className="text-dark">
                      Completed an advanced data center with fire and data
                      protection requirements, meeting stringent security and
                      safety standards.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`section-content production ${
              activeStep === 8 ? "active" : ""
            }`}
          >
             <div className="row timelineCaraousel">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <img
                      src={Caraousel7}
                      className="img-fluid"
                      alt="Company Timeline"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                  <h5 className="card-text">Cold Storage Facility</h5>
                    <h6>Project: <span>40,000sq.ft, 17M, 291 Tons</span></h6>
                    <h6>Site: <span>Coimbatore</span></h6>
                    <h6>Cumulative Project Area: <span>150,000 sqft</span></h6>
                    <p className="text-dark">
                    Delivered a sophisticated cold storage facility, integrating advanced structural measures.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`section-content production ${
              activeStep === 9 ? "active" : ""
            }`}
          >
                       <div className="row timelineCaraousel">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <img
                      src={Caraousel8}
                      className="img-fluid"
                      alt="Company Timeline"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-text">Large Food Park</h5>
                    <h6>Project: <span>80,000sq.ft, 375 Tons</span></h6>
                    <h6>Site: <span>North East India</span></h6>
                    <h6>Cumulative Project Area: <span>230,000 sqft</span></h6>
                    <p className="text-dark">
                    Completed a large food park, integrating multiple process lines and logistics solutions, 
                    and achieving significant cost savings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`section-content production ${
              activeStep === 10 ? "active" : ""
            }`}
          >
           <div className="row timelineCaraousel">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <img
                      src={Caraousel9}
                      className="img-fluid"
                      alt="Company Timeline"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-text">Comprehensive Food Park</h5>
                    <h6>Project: <span>20 Acre Food Park</span></h6>
                    <h6>Cumulative Project Area: <span>1,000,000 sqft (approximately 20 acres)</span></h6>
                    <p className="text-dark">
                    Undertook the comprehensive design, engineering, and construction of a 20-acre food park, demonstrating our full-service capabilities and 
                    commitment to delivering large-scale turnkey solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Tabs;
