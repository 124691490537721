import React from 'react';
import '../styles/Form.css';

const Form = ({ closeForm }) => {
  return (
    <div className="quote-form-overlay">
      <div className="quote-form-container">
        <div className="card">
          <div className="card-header text-right">
            <button type="button" className="close" aria-label="Close" onClick={closeForm}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card-body">
            <h5 className="card-title">
              Get a <span className="textQuote">free quote</span>
            </h5>
            <form>
              <div className="row form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputName">Your Name:</label>
                  <input type="text" className="form-control" id="inputName" />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputEmail">Email:</label>
                  <input type="email" className="form-control" id="inputEmail" />
                </div>
              </div>
              <div className="row form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputService">Service type:</label>
                  <input type="text" className="form-control" id="inputService" />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputContact">Contact No.:</label>
                  <input type="text" className="form-control" id="inputContact" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="inputMessage">Message:</label>
                <textarea className="form-control" id="inputMessage" rows="3"></textarea>
              </div>
              <div className="text-center">
                <button type="submit" className="mt-4 btn btn-primary mx-auto">
                  Get Quote
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;

